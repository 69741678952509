<template>
  <div class="goods_category" @touchmove.prevent="">
    <top-head
      :sellerinfo="sellerInfo"
      @left="leftHandle"
      @right="rightHandle"
      @search="search"
      @details="details"
      @follow="follow"
    />

    <div class="goods_category_content">
      <order-bar @change="orderChange" @woned="handleWoned" />
      <div class="category_content">
        <scroller
          ref="my_scroll"
          :noDataText="noDataText"
          :on-infinite="infinite"
          :on-refresh="refresh"
        >
          <div class="goods_list flex_sb">
            <goods-item
              :info="item"
              height="170px"
              v-for="item in listData"
              :key="item.id"
              @jump="handleGoods"
            >
              <p class="evaluate">500+条评价 98%好评</p>
            </goods-item>
          </div>
        </scroller>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import headEvent from "@/mixin/headEvent.js";
import topHead from "components/shop/topHead.vue";
import orderBar from "./components/orderBar.vue";
import goodsItem from "components/common/goodsItem.vue";
export default {
  components: { topHead, orderBar, goodsItem },
  mixins: [headEvent, scrollMethods, handleGoods],
  data() {
    return { isHot: true, sortColumn: "", isAsc: false, asc: true };
  },
  computed: {
    ...mapState({
      sellerInfo: (state) => state.seller.sellerInfo,
      id() {
        return this.$route.query.shopId;
      },
    }),
  },
  watch: {},
  methods: {
    ...mapActions({ load: "seller/loadGoodsList" }),
    async loadInfo(isRefresh = false) {
      const params = {
        shopId: this.id,
        current: this.pages,
        isHot: 1,
        sortColumn: this.sortColumn,
        asc: this.asc,
      };
      if (!this.isHot) {
        delete params.isHot;
      }
      if (!this.sortColumn) {
        delete params.sortColumn;
      }
      if (!this.isAsc) {
        delete params.asc;
      }
      const data = await this.load({ ...params });
      this.dataLength = data.records.length;
      if (isRefresh) {
        this.listData = data.records;
      } else {
        this.listData = this.listData.concat(data.records);
      }
      return data;
    },
    orderChange(item) {
      this.pages = 1;
      this.sortColumn = item.sortColumn;
      this.listData = [];
      if (item.sortColumn === "price_down") {
        this.isAsc = true;
        this.asc = item.asc;
      } else {
        this.isAsc = false;
      }
      if (item.sortColumn === "isHot") {
        this.isHot = true;
      } else {
        this.isHot = false;
      }
      this.loadInfo();
    },
    handleWoned(value) {
      console.log("是否仅看有货：", value);
    },
  },
  created() {
    this.loadInfo();
  },
};
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>
